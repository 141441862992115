.banner {
  position: relative;
  z-index: 1;
  top: -203px;
  margin-bottom: -290px;
  @media (max-width: 850px) {
    top: -460px;
    margin-bottom: -500px;
  }
  @media (max-width: 368px) {
    top: -495px;
    margin-bottom: -620px;
  }
  &__container {
    margin: 0 auto;
    text-align: center;
  }

  --ratioTablet: 1024 / 768;
  --ratioMobile: 568 / 320;

  &__wc1 {
    background-image: url("./../../assets/images/wc1.webp");
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    height: 95.5vh;
    @media (max-width: 1537px) {
      height: 95vh;
    }
    @media (max-width: 1441px) {
      background-size: 100% 100%;
      background-image: url("./../../assets/images/wc1-1440.webp");
    }
    @media (max-width: 1367px) {
      height: 94vh;
    }
    @media (max-width: 1025px) {
      background-size: 100% 100%;
      background-image: url("./../../assets/images/wc1-1024.webp");
    }
    @media (max-width: 913px) {
      background-size: 200% 100%;
      height: 97vh;
    }
    @media (max-width: 850px) {
      background-image: url("./../../assets/images/wc1Tablet.webp");
      background-position: 0 0;
      background-repeat: no-repeat;
      height: calc(104vw * var(--ratioTablet));
      background-size: 100% 100%;
    }
    @media (max-width: 450px) {
      background-image: url("./../../assets/images/wc1Mobile.webp");
      background-position: 0 0;
      background-repeat: no-repeat;
      height: calc(116vw * var(--ratioMobile));
      background-size: 100% 100%;
    }
    &_text {
      font-size: 32px;
      font-weight: 400;
      font-family: "Candal", sans-serif;
      color: #fff;
      position: absolute;
      bottom: 10%;
      left: 50%;
      width: 85%;
      transform: translate(-50%);
      @media (max-width: 850px) {
        font-size: 22px;
      }
      @media (max-width: 450px) {
        font-size: 16px;
        bottom: 7%;
      }
      @media (max-width: 361px) {
        font-size: 14px;
      }
      @media (max-width: 330px) {
        font-size: 13px;
      }
    }
  }
  &__wc2 {
    background-image: url("./../../assets/images/wc2.webp");
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    height: 95.5vh;
    @media (max-width: 1537px) {
      height: 95vh;
    }
    @media (max-width: 1441px) {
      background-size: 100% 100%;
      background-image: url("./../../assets/images/wc2-1440.webp");
    }
    @media (max-width: 1367px) {
      height: 94vh;
    }
    @media (max-width: 1025px) {
      background-size: 100% 100%;
      background-image: url("./../../assets/images/wc2-1024.webp");
    }
    @media (max-width: 913px) {
      background-size: 200% 100%;
      height: 97vh;
    }
    @media (max-width: 850px) {
      background-image: url("./../../assets/images/wc2Tablet.webp");
      background-position: 0 0;
      background-repeat: no-repeat;
      height: calc(104vw * var(--ratioTablet));
      background-size: 100% 100%;
    }
    @media (max-width: 450px) {
      background-image: url("./../../assets/images/wc2Mobile.webp");
      background-position: 0 0;
      background-repeat: no-repeat;
      height: calc(116vw * var(--ratioMobile));
      background-size: 100% 100%;
    }
    &_text {
      font-size: 40px;
      font-weight: 400;
      font-family: "Candal", sans-serif;
      color: #fff;
      position: absolute;
      top: 200px;
      left: 50%;
      width: 85%;
      transform: translate(-50%);
      @media (max-width: 850px) {
        font-size: 22px;
        top: auto;
        bottom: 10%;
        left: 50%;
        width: 85%;
        transform: translate(-50%);
      }
      @media (max-width: 450px) {
        font-size: 16px;
        bottom: 7%;
      }
      @media (max-width: 361px) {
        font-size: 14px;
      }
      @media (max-width: 330px) {
        font-size: 13px;
      }
    }
  }
  &__wc3 {
    background-image: url("./../../assets/images/wc3.webp");
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    height: 95.5vh;
    @media (max-width: 1537px) {
      height: 95vh;
    }
    @media (max-width: 1441px) {
      background-size: 100% 100%;
      background-image: url("./../../assets/images/wc3-1440.webp");
    }
    @media (max-width: 1367px) {
      height: 94vh;
    }
    @media (max-width: 1025px) {
      background-size: 100% 100%;
      background-image: url("./../../assets/images/wc3-1024.webp");
    }
    @media (max-width: 913px) {
      background-size: 200% 100%;
      height: 97vh;
    }
    @media (max-width: 850px) {
      background-image: url("./../../assets/images/wc3Tablet.webp");
      background-position: 0 0;
      background-repeat: no-repeat;
      height: calc(104vw * var(--ratioTablet));
      background-size: 100% 100%;
    }
    @media (max-width: 450px) {
      background-image: url("./../../assets/images/wc3Mobile.webp");
      background-position: 0 0;
      background-repeat: no-repeat;
      height: calc(116vw * var(--ratioMobile));
      background-size: 100% 100%;
    }
    &_text {
      font-size: 40px;
      font-weight: 400;
      font-family: "Candal", sans-serif;
      color: #fff;
      position: absolute;
      width: 40%;
      top: 350px;
      left: 74%;
      transform: translate(-50%, -50%);
      @media (max-width: 1250px) {
        font-size: 32px;
        width: 50%;
      }
      @media (max-width: 850px) {
        font-size: 22px;
        top: auto;
        bottom: 10%;
        left: 50%;
        width: 85%;
        transform: translate(-50%);
      }
      @media (max-width: 450px) {
        font-size: 16px;
        bottom: 7%;
      }
      @media (max-width: 361px) {
        font-size: 14px;
      }
      @media (max-width: 330px) {
        font-size: 13px;
      }
    }
  }
  &__wc4 {
    background-image: url("./../../assets/images/wc4.webp");
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    height: 95.5vh;
    @media (max-width: 1537px) {
      height: 95vh;
    }
    @media (max-width: 1441px) {
      background-size: 100% 100%;
      background-image: url("./../../assets/images/wc4-1440.webp");
    }
    @media (max-width: 1367px) {
      height: 94vh;
    }
    @media (max-width: 1025px) {
      background-size: 100% 100%;
      background-image: url("./../../assets/images/wc4-1024.webp");
    }
    @media (max-width: 913px) {
      background-size: 200% 100%;
      height: 97vh;
    }
    @media (max-width: 850px) {
      background-image: url("./../../assets/images/wc4Tablet.webp");
      background-position: 0 0;
      background-repeat: no-repeat;
      height: calc(104vw * var(--ratioTablet));
      background-size: 100% 100%;
    }
    @media (max-width: 450px) {
      background-image: url("./../../assets/images/wc4Mobile.webp");
      background-position: 0 0;
      background-repeat: no-repeat;
      height: calc(116vw * var(--ratioMobile));
      background-size: 100% 100%;
    }
    &_text {
      font-size: 40px;
      font-weight: 400;
      font-family: "Candal", sans-serif;
      color: #fff;
      position: absolute;
      top: 200px;
      left: 50%;
      width: 85%;
      transform: translate(-50%);
      @media (max-width: 850px) {
        font-size: 22px;
        top: auto;
        bottom: 10%;
        left: 50%;
        width: 85%;
        transform: translate(-50%);
      }
      @media (max-width: 450px) {
        font-size: 16px;
        bottom: 7%;
      }
      @media (max-width: 361px) {
        font-size: 14px;
      }
      @media (max-width: 330px) {
        font-size: 13px;
      }
    }
  }
  &__wc5 {
    background-image: url("./../../assets/images/wc5.webp");
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    height: 95.5vh;
    @media (max-width: 1537px) {
      height: 95vh;
    }
    @media (max-width: 1441px) {
      background-size: 100% 100%;
      background-image: url("./../../assets/images/wc5-1440.webp");
    }
    @media (max-width: 1367px) {
      height: 94vh;
    }
    @media (max-width: 1025px) {
      background-size: 100% 100%;
      background-image: url("./../../assets/images/wc5-1024.webp");
    }
    @media (max-width: 913px) {
      background-size: 200% 100%;
      height: 97vh;
    }
    @media (max-width: 850px) {
      background-image: url("./../../assets/images/wc5Tablet.webp");
      background-position: 0 0;
      background-repeat: no-repeat;
      height: calc(104vw * var(--ratioTablet));
      background-size: 100% 100%;
    }
    @media (max-width: 450px) {
      background-image: url("./../../assets/images/wc5Mobile.webp");
      background-position: 0 0;
      background-repeat: no-repeat;
      height: calc(116vw * var(--ratioMobile));
      background-size: 100% 100%;
    }
    &_text {
      font-size: 40px;
      font-weight: 400;
      font-family: "Candal", sans-serif;
      color: #fff;
      position: absolute;
      width: 40%;
      top: 350px;
      left: 75%;
      transform: translate(-50%, -50%);
      @media (max-width: 850px) {
        font-size: 22px;
        top: auto;
        bottom: 10%;
        left: 50%;
        width: 85%;
        transform: translate(-50%);
      }
      @media (max-width: 450px) {
        font-size: 16px;
        bottom: 7%;
      }
      @media (max-width: 361px) {
        font-size: 14px;
      }
      @media (max-width: 330px) {
        font-size: 13px;
      }
    }
  }
}

//swiper

.swiper-pagination {
  margin-bottom: 5px;
}
