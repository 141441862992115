.sliderLeagues {
  &__container {
    padding: 0px 40px;
    margin-bottom: 100px;
    @media (max-width: 400px) {
      padding: 0px 20px;
    }
    @media (max-width: 350px) {
      padding: 0px 10px;
    }
  }
  &__title {
    margin-top: 175px;
    margin-bottom: 50px;
    font-family: "Candal", sans-serif;
    font-size: 24px;
    text-shadow: 1px 1px 1px #ffffff;
    text-align: center;
  }
  &__item {
    text-align: center;
    background-color: #ffffff;
    width: 225px;
    height: 300px;
    margin: 0 auto;
    border-radius: 8px;
    &_img {
      height: 100px;
      margin-top: 20px;
      margin-bottom: 20px;
      padding: 0px 10px;
    }
    &_title {
      color: #333333;
      font-weight: 600;
      margin-bottom: 5px;
      text-align: center;
      padding: 0px 15px;
    }
    &_text {
      color: #525252;
      line-height: 1.1em;
      padding: 0px 15px;
      font-size: 14px;
    }
  }
}

//custom swiper

.swiper-button-prev:after,
.swiper-rtl .swiper-button-next:after,
.swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after {
  color: #b70000;
  padding: 10px;
}
