.preloader {
  display: flex;
  width: 100%;
  height: 100vh;
  background-image: url(./../../assets/images/preloader_bg.jpg);
  background-size: cover;
  position: relative;
  &__image {
    &_animate {
      background-image: url(./../../assets/images/preloader.png);
      background-size: cover;
      width: 200px;
      height: 200px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
