.sliderPartners {
  &__container {
    padding: 0px 40px;
    margin-bottom: 100px;
    @media (max-width: 400px) {
      padding: 0px 20px;
    }
    @media (max-width: 350px) {
      padding: 0px 10px;
    }
  }
  &__title {
    margin-bottom: 50px;
    font-family: "Candal", sans-serif;
    font-size: 24px;
    text-shadow: 1px 1px 1px #ffffff;
    text-align: center;
  }
  &__item {
    height: 100px;
    text-align: center;
    border-radius: 8px;
    &_img {
      border-radius: 8px;
      box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
      rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
      cursor: pointer;
      transition: 0.8s;
    }
    &_img:hover {
      transform: scale(1.1);
    }
  }
}

//swiper bullet custom

.swiper-pagination-bullet-active {
  background-color: #b70000;
}