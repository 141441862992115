.header {
  position: relative;
  z-index: 10;
  &__menu {
    padding: 15px 40px 15px 40px;
    @media (max-width: 430px) {
      padding: 15px 20px 15px 10px;
    }
    background-color: #000;
    ul {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      @media (max-width: 1024px) {
        justify-content: center;
      }
    }
  }
  &__link {
    margin-left: 20px;
    a {
      font-family: "Candal", sans-serif;
      color: #fff;
      @media (max-width: 380px) {
        font-size: 12px;
      }
    }
  }
  &__bottomBox {
    padding: 0px 40px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    @media (max-width: 850px) {
      flex-direction: column;
      align-items: center;
      margin-top: 148px;
    }
    @media (max-width: 600px) {
      margin-top: 25px;
      margin-bottom: 120px;
    }
  }
  &__logo {
    &_img {
      max-width: 200px;
      transition: 0.8s;
      overflow: hidden;
    }
    &_img:hover {
      transform: scale(1.1);
    }
  }
  &__button {
    margin-top: 45px;
    @media (max-width: 850px) {
    }
    a {
      display: block;
      padding: 20px;
      background-color: #b70000;
      border-radius: 8px;
      border-style: none;
      box-sizing: border-box;
      color: #fcd066;
      flex-shrink: 0;
      font-family: "Candal", sans-serif;
      font-size: 16px;
      font-weight: 600;
      text-align: center;
      text-shadow: rgba(0, 0, 0, 0.25) 0 3px 8px;
      transition: all 0.5s;
      touch-action: manipulation;
      cursor: pointer;
    }

    a:hover {
      box-shadow: #b7000075 0 1px 30px;
      transition-duration: 0.1s;
    }
  }
}

.active {
  color: #fc0303 !important;
}
