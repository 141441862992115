.requestForm {
  &__container {
    margin-top: 150px;
    margin-bottom: 150px;
  }
  &__box {
    max-width: 90%;
    margin: 0 auto;
    padding: 15px 20px;
    background-color: #dddddd;
    border-radius: 8px;
    box-shadow: rgb(38 57 77) 0px 20px 30px -10px;
  }
  &__title {
    margin-bottom: 25px;
    font-family: "Candal", sans-serif;
    font-size: 24px;
    text-shadow: 1px 1px 1px #ffffff;
  }
  &__form {
    display: flex;
    flex-wrap: wrap;
    @media(max-width:1024px) {
      flex-direction: column;
      align-items: center;
    }
    @media(max-width:600px) {
      align-items:flex-start;
    }
  }
  &__contacts {
    width: 50%;
    display: flex;
    flex-direction: column;
    @media(max-width:1024px) {
      width: 75%;
    }
    @media(max-width:600px) {
      width: 100%;
    }
  }
  &__message {
    width: 50%;
    display: flex;
    flex-direction: column;
    @media(max-width:1024px) {
      width: 100%;
    }
  }
  &__submit {
    width: 100%;
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media(max-width:600px) {
      flex-wrap: wrap;
    }
  }
  &__label {
    font-family: "Candal", sans-serif;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 5px;
  }
  &__input {
    max-width: 50%;
    padding: 15px;
    height: 18px;
    font-size: 14px;
    background: #fff;
    border: 1px solid #9c9c9c;
    -webkit-box-shadow: inset 0px 1px 8px -2px #000;
    -moz-box-shadow: inset 0px 1px 8px -2px #000;
    box-shadow: inset 0px 1px 8px -2px #000;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -o-border-radius: 5px;
    border-radius: 5px;
    @media(max-width:1024px) {
      max-width: 100%;
    }
  }

  &__textarea {
    width: 100%;
    height: 115px;
    background: #fff;
    border: 1px solid #9c9c9c;
    color: #000;
    resize: none;
    overflow: auto;
    padding: 15px;
    font-size: 16px;
    -webkit-box-shadow: inset 0px 1px 8px -2px #000;
    -moz-box-shadow: inset 0px 1px 8px -2px #000;
    box-shadow: inset 0px 1px 8px -2px #000;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -o-border-radius: 5px;
    border-radius: 5px;
  }
  &__code {
    width: 100px;
    font-size: 14px;
    height: 23px;
    background: #fff;
    border: 1px solid #9c9c9c;
    -webkit-box-shadow: inset 0px 1px 8px -2px #000;
    -moz-box-shadow: inset 0px 1px 8px -2px #000;
    box-shadow: inset 0px 1px 8px -2px #000;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -o-border-radius: 5px;
    border-radius: 5px;
    padding: 15px;
  }
  &__error {
    font-size: 12px;
    color: #f44336;
  }
  &__captcha {
    width: 100px;
    @media(max-width:600px) {
      margin: 0px 10px 0px 10px;
    }
    @media(max-width:368px) {
      margin: 0px 0px 0px 0px;
    }
     
  }
  &__button {
    background: linear-gradient(to bottom right, #fc0303, #b70000);
    border: 0;
    border-radius: 8px;
    color: rgb(209, 233, 27);
    display: inline-block;
    font-family: "Candal", sans-serif;
    font-size: 16px;
    line-height: 2.5;
    font-weight: 600;
    outline: transparent;
    padding: 0 1rem;
    text-align: center;
    text-decoration: none;
    transition: box-shadow 0.2s ease-in-out;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    white-space: nowrap;
    cursor: pointer;
    @media(max-width:600px) {
      margin: 20px 0px 0px 0px;
    }
  }

  &__button:not([disabled]):focus {
    box-shadow: 0 0 0.25rem #fc030381, -0.125rem -0.125rem 1rem #fc030381,
      0.125rem 0.125rem 1rem #b70000;
  }

  &__button:not([disabled]):hover {
    box-shadow: 0 0 0.25rem #fc030381, -0.125rem -0.125rem 1rem #fc030381,
      0.125rem 0.125rem 1rem #b70000;
  }
}
