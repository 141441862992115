/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #bababa;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #b70000;
}

html {
  background: #6b6a6a;
}

body {
  max-width: 1920px;
  width: 100% !important;
  margin: 0 auto !important;
}

#root {
  background: linear-gradient(#fcf9f9, #6b6a6a);
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

main {
  flex: 1 1 auto;
}

.loader {
  animation: loadering 1s forwards;
}

@keyframes loadering {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 0.1;
  }
  20% {
    opacity: 0.2;
  }
  30% {
    opacity: 0.3;
  }
  40% {
    opacity: 0.4;
  }
  50% {
    opacity: 0.5;
  }
  60% {
    opacity: 0.6;
  }
  70% {
    opacity: 0.7;
  }
  80% {
    opacity: 0.8;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
