.footer {
  &__texts {
    padding: 20px 40px;
    background-color: #525050;
    color: #b5b5b5;
    font-size: 11px;
    font-weight: 500;
    line-height: 1.2em;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
      rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
      rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  }
  &__item {
    margin-bottom: 20px;
  }
  &__image {
    background: url('./../../assets/images/footer.jpg');
    --ratio: 1200 / 211;
    width: 100%;
    height: calc(1.5vw * var(--ratio));
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    background-position-y: bottom;
    box-shadow: 0px -25px 20px -20px rgb(0 0 0 / 45%);
    -webkit-filter: blur(1px);
    filter: blur(1px);
  }
}
