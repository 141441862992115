*,
::before,
::after {
  padding: 0;
  margin: 0;

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: subpixel-antialiased;
  text-rendering: geometricPrecision;
}

body {
  overflow-x:hidden;
  margin: 0;
  padding: 0;
  font-family: "Helvetica Neue", sans-serif !important;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, Helvetica Neue, sans-serif; */
  -webkit-font-smoothing: subpixel-antialiased;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-touch-callout: none;
}

html,
body {
  height: 100%;
  min-height: 100%;
  font-feature-settings: kern 1, liga 1, calt 1, pnum 1, tnum 0, onum 1, lnum 0,
    dlig 0;
}

html,
body,
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
}

h1 {
  font-size: 2.2em;
}
h2 {
  font-size: 1.9em;
}
h3 {
  font-size: 1.65em;
}
h4 {
  font-size: 1.4em;
}
h5 {
  font-size: 1.2em;
}
h6 {
  font-size: 1em;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

[hidden],
template {
  display: none;
}

a {
  background: transparent;
  text-decoration: none;
  cursor: pointer;
}

a:active,
a:hover {
  outline: 0;
}

img {
  border: 0;
}

ul,
li {
  outline: 0;
  border: 0;
  list-style: none;
}

abbr {
  cursor: help;
  font-feature-settings: kern 1, liga 1, calt 1, pnum 1, tnum 0, onum 1, lnum 0,
    smcp 1, c2sc 1;
}

abbr[title] {
  text-decoration: none;
  border-bottom: 1px dotted;
}

b,
strong {
  font-weight: 700;
}

dfn {
  font-style: italic;
}

mark {
  background: #ff0;
  color: #000;
}

time {
  font-feature-settings: kern 1, liga 1, calt 1, pnum 1, tnum 0, onum 1, lnum 0;
}

small {
  font-weight: 400;
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
  font-feature-settings: kern 1, liga 1, calt 1, pnum 1, tnum 0, onum 1, lnum 0,
    dlig 0, sups 1;
}

sub {
  bottom: -0.25em;
  font-feature-settings: kern 1, liga 1, calt 1, pnum 1, tnum 0, onum 1, lnum 0,
    dlig 0, subs 1;
}

svg:not(:root) {
  overflow: hidden;
}

hr {
  height: 0;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

pre {
  overflow: auto;
}

button,
form,
input,
optgroup,
select,
textarea {
  outline: 0;
  color: inherit;
  font: inherit;
}

button,
select {
  border: 0;
  text-transform: none;
}

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}

button[disabled],
html input[disabled] {
  cursor: default;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
}

input {
  line-height: normal;
}

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

input[type="search"] {
  -webkit-appearance: textfield;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

input[type="color"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="number"],
input[type="range"],
input[type="tel"],
input[type="week"] {
  font-feature-settings: kern 0, liga 1, calt 1, pnum 1, tnum 0, onum 0, lnum 1,
    zero 0;
}

fieldset {
  border: 1px solid silver;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

legend {
  border: 0;
}

textarea {
  resize: vertical;
  overflow: auto;
}

optgroup {
  font-weight: 700;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

tbody,
caption {
  font-feature-settings: kern 1, liga 1, calt 1, pnum 0, tnum 1, onum 0, lnum 1,
    zero 1;
}

